import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { Action } from 'types'

const useActionCreator = <T extends (...args: any[]) => Partial<Action>>(actionCreator: T) => {
  const dispatch = useDispatch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const wrappedActionCreator = useCallback(((...args: any[]) => dispatch(actionCreator(...args) as any)) as T, [
    actionCreator,
    dispatch,
  ])
  return wrappedActionCreator
}

export default useActionCreator
