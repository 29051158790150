import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'

import { PLATFORM_NAME } from 'consts'

const LOCALE_FONTS = {
  ja: 'https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap',
}

export const translationsLoading = i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    preload: ['en'],
    ns: ['translation'],
    defaultNS: 'translation',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
      defaultVariables: {
        platformName: PLATFORM_NAME,
      },
    },
    react: {
      useSuspense: true,
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    },
    detection: {
      order: ['localStorage', 'navigator'],
    },
  })

const setHtmlLang = (lang) => document.querySelector('html').setAttribute('lang', lang)

const loadLocaleFontIfNeeded = (lang) => {
  const font = LOCALE_FONTS[lang]

  if (!font || !!document.querySelector(`link[href="${font}"]`)) return

  const link = document.createElement('link')
  link.setAttribute('rel', 'stylesheet')
  link.setAttribute('href', font)

  document.querySelector('head').appendChild(link)
}

window.loadFont = loadLocaleFontIfNeeded

i18n.on('languageChanged', (lang) => {
  setHtmlLang(lang)
  loadLocaleFontIfNeeded(lang)
})

i18n.on('failedLoading', () => {
  setHtmlLang('en')
})

export default i18n
