import { createAction } from '@reduxjs/toolkit'
import { PayloadWithMeta, PrepareFn } from 'types'
import { v4 as uuid } from 'uuid'

import { ReduxMethodNameSuffixes } from 'consts'

function prepare<P = void>(args: P | PayloadWithMeta<P>): PayloadWithMeta<P> {
  const payload = args && typeof args === 'object' && 'payload' in args ? args.payload : args
  const meta = args && typeof args === 'object' && 'meta' in args && args.meta ? args.meta : { operationUuid: uuid() }
  return { payload, meta }
}

export function prepareAction<P = void>(type: string) {
  return createAction<PrepareFn<P>>(type, prepare)
}

export function moduleAction(moduleName: string) {
  return (type: string) => `${moduleName}/${type}`
}

export function requestAction(baseActionType: string) {
  return `${baseActionType}_${ReduxMethodNameSuffixes.IN_PROGRESS}`
}

export function failureAction(baseActionType: string) {
  return `${baseActionType}_${ReduxMethodNameSuffixes.FAILED}`
}

export function successAction(baseActionType: string) {
  return `${baseActionType}_${ReduxMethodNameSuffixes.SUCCEEDED}`
}
