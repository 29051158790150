import React, { Suspense } from 'react'

import CenteredAbsolute from 'components/CenteredAbsolute'
import Loading from 'components/Loading'

import StylesProvider from './StylesProvider'
import { translationsLoading } from '../i18n'

const App = React.lazy(async () => {
  await translationsLoading
  return import('./App')
})

const Providers = React.lazy(async () => {
  await translationsLoading
  return import('./Providers')
})

const Root: React.FC = () => (
  <Suspense
    fallback={
      <CenteredAbsolute>
        <Loading />
      </CenteredAbsolute>
    }
  >
    <Providers>
      <StylesProvider>
        <App />
      </StylesProvider>
    </Providers>
  </Suspense>
)

export default Root
