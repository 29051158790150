import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'

import { RootState } from 'store/root.reducer'

export const createLoadingSelector = (actions: string[]) => (state: RootState) =>
  includes(
    actions.map((action) => !isEmpty(state.loading[action])),
    true,
  )
