import 'react-app-polyfill/stable'

import React from 'react'

import { createRoot } from 'react-dom/client'

import 'i18n'
import Root from './Root/Root'

const container = document.createElement('div')
const root = createRoot(container)
container.setAttribute('id', 'root')
document.body.prepend(container)
root.render(<Root />)

// unregister old bloated service workers
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister()
  })
}

if (module.hot) {
  module.hot.accept()
}
