import { createGlobalStyle } from 'styled-components'

import { getTheme } from 'theme'

const theme = getTheme({})

export const GlobalStyles = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
  }

  html {
    font-family: Open-Sans, 'Noto Sans JP', sans-serif;
    font-size: 14px;

    &.popover {
      iframe {
        pointer-events: none;
      }
    }
  }

  #root {
    height: 100vh;
  }

  ::-webkit-scrollbar {
    width: ${theme.sizes.scrollbar.size};
    height: ${theme.sizes.scrollbar.size};
  }

  ::-webkit-scrollbar-track {
    border-radius: ${theme.sizes.scrollbar.borderRadius};
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme.palette.grey[600]};
    border-radius: ${theme.sizes.scrollbar.borderRadius};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.palette.grey[500]};
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .MuiAccordion-root:before {
    background-color: rgba(0, 0, 0, .08);
  }

  .Toastify {
    z-index: 43;
    position: absolute;
  }
`

export const UserManualGlobalStyles = createGlobalStyle`
  @media print {
    #root {
      height: auto;
    }
  }
`
