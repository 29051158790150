import { useSelector } from 'react-redux'

import { createLoadingSelector } from 'services/loading/loading.selectors'

const useLoading = (actions: string[]) => {
  const isLoading = createLoadingSelector(actions)
  const loading = useSelector(isLoading)
  return loading
}

export default useLoading
