import React, { memo } from 'react'

import styled from 'styled-components'

import { CircularProgress, CircularProgressProps } from '@mui/material'

const StretchingWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  overflow: hidden;
  color: #3297a5;
`

const Loading: React.FC<CircularProgressProps> = (props) => (
  <StretchingWrapper>
    <CircularProgress color="inherit" data-e2e-loader {...props} />
  </StretchingWrapper>
)

export default memo(Loading)
