import React from 'react'

import { deepOrange, green, orange, teal } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

export const customPalette = {
  linearProgress: {
    background: '#b2dfdb',
    barBackgroundColor: '#3297A5',
  },
  stepper: {
    background: '#3297a514',
  },
  accent: {
    light: teal[200],
    main: teal[300],
    dark: teal[400],
  },
  panel: {
    selected: {
      row: deepOrange[50],
    },
  },
  success: {
    main: green[500],
  },
  warning: {
    main: orange[500],
  },
  danger: {
    main: '#D00026',
  },
  black: '#000000',
  lightGrey: 'rgba(0, 0, 0, 0.6)',
  lightGrey2: 'rgba(0, 0, 0, 0.54)',
  lightGrey3: 'rgba(0, 0, 0, 0.24)',
  lightGrey4: 'rgba(0, 0, 0, 0.16)',
  lightGrey5: '#FAFAFA',
  lightGrey6: 'rgba(0, 0, 0, 0.04)',
  lightGrey7: 'rgba(0, 0, 0, 0.38)',
  lightGrey8: 'rgba(33, 33, 33, 0.04)',
  lightGrey9: '#F3F3F3',
  lightGrey10: '#F7F7F7',
  lightGrey11: '#EAEAEA',
  lightGrey12: 'rgba(0, 0, 0, 0.08)',
  lightGrey13: 'rgba(0, 0, 0, 0.32)',
  lightGrey14: 'rgba(0, 0, 0, 0.06)',
  lightGrey15: 'rgba(0, 0, 0, 0.65)',
  transparentWhite: 'rgba(255, 255, 255, .6)',
  linkBlue: '#3297A5',
  lightBlue: '#B9DEF5',
  status: {
    unknown: {
      background: 'grey',
    },
    stopped: {
      background: '#b00020',
    },
    pending: {
      background: '#e36f32',
    },
    running: {
      background: '#f1f1f1',
    },
    failed: {
      background: '#B00020',
    },
    success: {
      background: '#07bc0c',
    },
  },
  notificationMessage: {
    warning: 'rgba(227, 111, 50, 0.12)',
    error: 'rgba(176, 0, 32, 0.06)',
    errorDarker: 'rgba(176, 0, 32, 0.24)',
    success: green[500],
  },
  auditorStatsColors: {
    danger: '#FF5252',
    warning: '#FFE082',
    optimal: '#ACE9CF',
  },
  menu: {
    background: '#FFFFFF',
    topBar: '#2B4151',
    leftIcons: '#9e9e9e',
    topIcons: '#ffffff',
    text: '#ffffff',
    selected: 'rgba(227, 111, 50, 0.2)',
  },
  levels: {
    danger: '#FF0000',
    warning: '#FFA800',
    optimal: '#00BC58',
  },
  riskLevel: {
    low: '#67BFCB',
    medium: '#FD955F',
    high: '#FF768F',
  },
  phases: {
    nonStarted: '#e36f32',
    draft: '#2b4151',
    completed: '#5bacb7',
  },
}

const chipsPalette = {
  green: '#3297A5',
  red: '#D00026',
  lightGreen: 'rgba(50, 151, 165, 0.12)',
  lightRed: 'rgba(208, 0, 38, 0.16)',
  orange: '#E36F32',
  black: '#494949',
  default: 'rgba(0, 0, 0, 0.06)',
  hoverColors: {
    green: 'rgba(50, 151, 165, 0.8)',
    red: 'rgba(208, 0, 38, 0.8)',
    lightGreen: 'rgba(50, 151, 165, 0.24)',
    lightRed: 'rgba(208, 0, 38, 0.24)',
    orange: 'rgba(227, 111, 50, 0.8)',
    black: 'rgba(0, 0, 0, 0.8)',
    default: 'rgba(0, 0, 0, 0.12)',
  },
}

const chartsPalette = {
  colors: {
    default: '#5E77FF',
  },
  pie: {
    colors: ['#5072F8', '#6583FA', '#6E8AFB', '#7F97FB', '#94A8FB', '#9EB0FC', '#AFBEFC', '#C1CCFD', '#CAD4FD'],
  },
}

const sizes = {
  sidebar: {
    miniMode: '72px',
    fullWidth: '272px',
  },
  scrollbar: {
    size: '6px',
    borderRadius: '3px',
  },
  header: {
    size: '64px',
  },
  dataTable: {
    header: '48px',
    actionBar: '72px',
  },
}

declare module '@mui/material/styles' {
  interface Theme {
    sizes: typeof sizes
  }

  interface ThemeOptions {
    sizes?: typeof sizes
  }

  interface Palette {
    custom: typeof customPalette
    charts: typeof chartsPalette
    chips: typeof chipsPalette
  }

  interface PaletteOptions {
    custom?: typeof customPalette
    charts?: typeof chartsPalette
    chips?: typeof chipsPalette
  }

  interface TypographyVariants {
    paragraph: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    paragraph?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    paragraph: true
  }
}

// follow this structure https://material-ui.com/customization/default-theme/
// put custom variables in `custom` property of the corresponding piece of the theme
export const getTheme = (colors?: Record<string, Record<string, string>>) => {
  return createTheme({
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            background: colors?.menu?.background,
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            backgroundColor: customPalette.linearProgress.background,
          },
          barColorPrimary: {
            backgroundColor: customPalette.linearProgress.barBackgroundColor,
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            '&$expanded': {
              margin: '8px 0 10px',
              boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)',
            },
          },
          expanded: {},
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            paddingTop: '5px !important',
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            margin: '4px 0',
          },
          root: {
            padding: '0 26px 0 16px',
            minHeight: '56px',
            '&$expanded': {
              borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
            },
          },
          expanded: {},
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '16px 24px 24px',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: 6,
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: 24,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            paddingTop: 9,
            paddingBottom: 9,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 'auto',
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            padding: '0 16px',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            height: '28px',
            backgroundColor: 'rgba(0, 0, 0, 0.06)',
          },
          outlined: {
            borderColor: '#3297A5',
          },
          label: {
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '12.5px',
            letterSpacing: '0.23px',
            lineHeight: '17px',
          },
          deleteIcon: {
            color: 'rgba(0, 0, 0, 0.16)',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '& > fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.6)',
            },
            height: '40px',
            fontSize: '1.1rem',
          },
          multiline: {
            height: 'unset',
          },
          formControl: {
            marginBottom: '14px',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            '&.Mui-error': {
              color: '#b00020 !important',
            },
          },
          contained: {
            margin: '-10px 12px 0',
            lineHeight: '10px',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontWeight: 'normal',
            color: customPalette.lightGrey,
            fontSize: 13.6,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderColor: 'rgba(255,0,0, 0.6)',
            // TODO: remove when issue solved: a workaround for this issue: https://github.com/mui/material-ui/issues/32934
            'input[required] + fieldset > legend > span': {
              paddingRight: 0,
              margin: '-1px',
            },
            '&.Mui-error': {
              color: '#d00026 !important',
              '& $.MuiOutlinedInput-notchedOutline': {
                borderColor: '#d00026 !important',
              },
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#e36f32 !important',
            },
            '&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
              borderColor: '#e36f32',
            },
          },
          input: {
            padding: '13.5px 16px',
            fontSize: '1.1rem',
          },
          multiline: {
            padding: '0px',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.Mui-error': {
              color: '#b00020 !important',
            },
          },
          outlined: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            minHeight: '16px',
            '&.Mui-focused': {
              color: '#e36f32 !important',
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: 'rgba(0, 0, 0, 0.54)',
            '&.Mui-focused': {
              color: '#e36f32',
            },
            '&.Mui-disabled': {
              color: 'rgba(0,0,0,0.4) !important',
            },
          },
          asterisk: {
            display: 'none',
          },
          filled: {
            color: '#e36f32',
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            '&.Mui-checked': {
              color: '#e36f32 !important',
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            '&$labelPlacementStart': {
              margin: 0,
            },
          },
          label: {
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: 13.6,
            letterSpacing: '0.25px',
            lineHeight: '20px',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            lineHeight: '1.3em',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: '6px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&$disabled': {
              color: 'rgba(227,111,50,0.38)',
            },
          },
          contained: {
            '&$disabled': {
              backgroundColor: 'rgba(227, 111, 50, 0.3)',
              color: '#fff',
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          input: {
            '&.MuiAutocomplete-inputRoot': {
              minHeight: '30px',
            },
          },
          inputRoot: {
            height: 'unset',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '18px 24px 24px',
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: '#3297A5',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            '&:last-child': {
              paddingBottom: '18px',
            },
          },
        },
      },
    },
    palette: {
      custom: {
        ...customPalette,
        menu: {
          background: colors?.menu?.background || customPalette.menu.background,
          topBar: colors?.menu?.topBar || customPalette.menu.topBar,
          leftIcons: colors?.menu?.leftIcons || customPalette.menu.leftIcons,
          topIcons: colors?.menu?.topIcons || customPalette.menu.topIcons,
          text: colors?.menu?.text || customPalette.menu.text,
          selected: colors?.menu?.selected || customPalette.menu.selected,
        },
      },
      divider: 'rgba(0, 0, 0, .16)',
      primary: {
        main: '#494949',
      },
      secondary: {
        light: '#F2B899',
        main: '#e36f32',
      },
      background: {
        default: '#f1f1f1',
      },
      error: {
        main: '#b00020',
      },
      charts: chartsPalette,
      chips: chipsPalette,
    },
    typography: {
      fontFamily: '"Open Sans", "Noto Sans JP", sans-serif',
      fontSize: 13.6,
      h1: {
        fontSize: '32px',
        fontWeight: 600,
        lineHeight: '45px',
        letterSpacing: '.44px',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      h2: {
        fontSize: '28px',
        fontWeight: 600,
        lineHeight: '39px',
        letterSpacing: '.38px',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      h3: {
        fontSize: '25px',
        fontWeight: 600,
        lineHeight: '35px',
        letterSpacing: '.34px',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      h4: {
        fontSize: '22px',
        fontWeight: 600,
        lineHeight: '30px',
        letterSpacing: '.3px',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      h5: {
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '27px',
        letterSpacing: '.25px',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      h6: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '24px',
        letterSpacing: '.25px',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      body1: {
        fontSize: '15.5px',
        fontWeight: 400,
        lineHeight: '23px',
        letterSpacing: '.5px',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      body2: {
        fontSize: '13.6px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '.25px',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      subtitle1: {
        fontSize: '15.4px',
        fontWeight: 600,
        lineHeight: '24px',
        letterSpacing: '.15px',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      subtitle2: {
        fontSize: '13.5px',
        fontWeight: 600,
        lineHeight: '21px',
        letterSpacing: '.1px',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      caption: {
        fontSize: '11px',
        fontWeight: 400,
        lineHeight: '15px',
        letterSpacing: '.38px',
        color: 'rgba(0, 0, 0, 0.54)',
      },
      overline: {
        fontSize: '11.53px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '2px',
        textTransform: 'uppercase',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      button: {
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '1.3px',
        fontWeight: 600,
        textTransform: 'uppercase',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      paragraph: {
        color: '#000000',
        fontSize: '13.5px',
        fontWeight: 400,
        lineHeight: '15px',
        letterSpacing: '0.38px',
      },
    },
    sizes,
  })
}
